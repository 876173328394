<template src="../templates/filter.html"></template>
<script>

import FormInput from '@f/Input';
import FormCheckbox from '@f/Check';
import FormButton from '@f/Button';

export default {
    name: 'Filter',
    components: {
        FormInput,
        FormCheckbox,
        FormButton,
    },
    props: {
        onChange: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
        onNewPartner: {
            type: Function,
            default() {
                return Function.prototype;
            }
        }
    },
    data() {
        return {
            filterValue: '',
            allRegions: false
        };
    },
    watch: {
        filterValue() {
            this.onChange(this.filterValue, {allRegions: this.allRegions});
        },
        allRegions() {
            this.onChange(this.filterValue, {allRegions: this.allRegions});
        }
    },
};
</script>
