<div>
	<div
		v-if="partnerFeed && partnerFeed.partners && partnerFeed.partners.length"
		class="row c mt-4"
	>
		<div class="row-col">
			<div class="col">
				<Tabs :columns="formatColumns(partnerFeed)" :data="formatData(partnerFeed)">
					<template v-slot:partner_grid="row">
						<div v-if="row.data">
							<Grid
								:columns="[
									{
										slot_name: 'slot_partner_name',
										label: 'Контрагент',
										width: 'minmax(200px, 1fr)',
									},
									{
										name: 'external_id',
										label: 'Код',
										width: 'minmax(10px, 0.3fr)',
									},
									{
										name: 'itn',
										label: 'ИНН',
										width: 'minmax(50px, 0.3fr)',
									},
									{
										name: 'area',
										label: 'Регион',
										width: 'minmax(50px, 0.5fr)',
									},
									{
										slot_name: 'slot1',
										label: 'Действия',
										width: 'minmax(50px, 1fr)',
									},
								]"
								:data="filterPartners(row.data.ids)"
								:onEdit="undefined"
								:onRemove="undefined"
								:formatters="{
									area: area => (area) ? area.name : ''
								}"
							>
								<template v-slot:slot1="row">
									<div v-if="row.data.contactInformationPartners && contactPositions">
										<div v-for="partner in row.data.contactInformationPartners" class="mb-1 d-flex-only">
											<FormRadio
												@change="changePartnerContactId(partner.id)"
												:label="partner.name + ((partner.position_id) ? ' (' + contactPositions[partner.position_id].name + ')' : '')"
												:attributes="{
													name:'selectPartner',
													value: partner.id
												}" wrapper-class="inline"
											/>
											<tippy
												:key="'partnerDetails-'+ partner.id"
												:content="partner.contactDetails.map(e => contactTypes[e.contact_data_type_id] + ': ' + e.data).join('<br />')"
												allowHTML arrow theme="big"
											>
												<span class="icon info mr-0 ml-1 inline"></span>
											</tippy>
										</div>
										<FormButton
											v-if="
												selectedPartnerContactId
												&& row.data.contactInformationPartners.map(a => a.id).includes(selectedPartnerContactId)
											"
											type="a"
											label="Провести опрос"
											@click="actionSelectPartner(row.data, selectedPartnerContactId)"
										/>
									</div>
								</template>
								<template v-slot:slot_partner_name="row">
									<FormButton
										type="a"
										:attributes="{'class':['btn-sm', 'btn-link', 'no-bg', 'btn-white']}"
										:label="row.data.name"
										@click="changePartner(row.data.id)"
									/>
								</template>
							</Grid>
						</div>
						<div class="no-bg"></div>
					</template>
				</Tabs>
			</div>
		</div>
	</div>
	<div v-else class="row c mt-4">
		<div class="row-col w-100">
			<div class="col">
				Введите в поиск любой из параметров контрагента: наименование, телефон, e-mail, Instagram, ИНН, район или их сочетание.
			</div>
		</div>
	</div>
</div>
