<div>
	<PageHeader>
		<div class="row c">
			<Breadcrumbs :items="[{title:'Контрагенты'}]" />
		</div>
	</PageHeader>

	<PageTitle title="Контрагенты" :params="{/*subtitle:'Список контрагентов'*/}" />

	<Filter :on-change="findResults" :on-new-partner="createPartner" />

	<PartnerGrid
		:partner-feed="partnerFeed"
		:contact-positions="contactPositions"
		:changePartner="changePartner"
        :action-select-partner="actionSelectPartner"
	/>

	<teleport to="body" v-if="showModal">
		<Modal
				v-if="showModal"
				@onOk="closeModal"
				@onClose="closeModal"
		>
			<template v-slot:body>
				<PartnerCart
					v-model:id="changedPartner"
					v-model:global-mode="mode"
					:on-cancel="cancelPartnerEdit"
                    :actions="actions"
				/>
			</template>
		</Modal>
	</teleport>
</div>
