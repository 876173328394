<template src="../templates/partner-grid.html"></template>
<script>

import Grid from '@c/Grid';
import FormButton from '@f/Button';
import FormRadio from '@f/Radio';
import Tabs from '@c/Tabs';
import Loader from '@c/Loader';

export default {
    name: 'PartnerGrid',
    components: {
        Grid,
        FormButton,
        FormRadio,
        Tabs,
        Loader,
    },
    props: {
        partnerFeed: {
            type: Object,
            default() {
                return null;
            }
        },
        contactPositions: {
            type: Object,
            default() {
                return null;
            }
        },
        // Выбор партнера для карточки контрагента
        changePartner: {
            type: Function,
            default() {
                return () => null;
            }
        },
        // Выбор парнера для страниц CRM
        actionSelectPartner: {
            type: Function,
            default() {
                return (partner, contactId) => { console.log(partner, contactId); };
            }
        }
    },
    data() {
        return {
            selectedPartnerContactId: null,
            disabledBlocks: []
        };
    },
    computed: {
        partners() {
            const p = {};
            if (this.partnerFeed.partners && this.partnerFeed.partners.length) {
                for (let i = 0; i < this.partnerFeed.partners.length; i++) {
                    p[this.partnerFeed.partners[i].id] = this.partnerFeed.partners[i];
                }
            }
            return p;
        },
        contactTypes() {
            return this.partnerFeed.contact_types;
        },
    },
    methods: {
        filterPartners(ids) {

            const result = [];
            if (ids.length) {
                for (let i = 0; i < ids.length; i++) {
                    if (this.partners[ids[i]]) {
                        result.push(this.partners[ids[i]]);
                    }
                }
            }

            return result;
        },
        changePartnerContactId(partnerContactId) {
            this.selectedPartnerContactId = partnerContactId;
        },
        toogleBlock(blockNum) {
            if (this.disabledBlocks.includes(blockNum)) {
                this.disabledBlocks = this.disabledBlocks.filter(i => i !== blockNum);
            } else {
                this.disabledBlocks.push(blockNum);
            }
        },

        formatColumns(feed) {

            if (!feed || !feed.filters) { return []; }

            const result = [];
            Object.entries(feed?.filters).forEach(
                ([key, value]) => {

                    if (value.ids.length) {
                        result.push({
                            id: key,
                            label: `${value.title} (${value.ids.length})`,
                            slot_name: 'partner_grid'
                        });
                    }
                }
            );
            return result;
        },
        formatData(feed) {

            if (!feed || !feed.filters) { return []; }

            const result = {};
            Object.entries(feed?.filters).forEach(
                ([key, value]) => {
                    if (value.ids.length) {
                        result[key] = value;
                    }
                }
            );

            return result;
        }
    }
};
</script>
