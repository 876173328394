<div class="container">
	<div class="row filter-block c mt-4 mb-3 flex-direction-column">
		<div class="row-col w-100">
			<div class="col">
				<FormInput
					v-model="filterValue"
					:attributes="{placeholder:'Поиск контрагентов'}"
				/>
			</div>
			<div class="col-3">
				<FormButton
					label="Создать контрагента"
					:attributes="{class: ['btn-green', 'w100']}"
					@click="onNewPartner()"
				/>
			</div>
		</div>
		<div class="row-col mt-1">
			<div class="col">
				<FormCheckbox
					v-model="allRegions"
					label="Поиск по всем регионам"
					:attributes="{placeholder:'Поиск контрагентов'}"
				/>
			</div>
		</div>
	</div>
</div>
