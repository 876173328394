<template src="./templates/page.html"></template>
<script>

import './styles/info-block.scss';

import PageTitle from '@c/PageTitle';
import Breadcrumbs from '@c/Breadcrumbs';
import PageHeader from '@c/Header';
import Modal from '@c/Modal';

import {
    findPartners, getContactPositions
} from '@api/partners';

import FilterLib from '@/entities/filter';
import filterHelper from '@/entities/filter_helper';
import Filter from './components/filter';
import PartnerGrid from './components/partner-grid';
import PartnerCart from './components/partner-cart/index';
import helpers from '../../tools/helpers';

const ACTION_CART = 'cart';

export default {
    name: 'PartnerList',
    components: {
        PageTitle,
        PageHeader,
        Breadcrumbs,
        PartnerGrid,
        PartnerCart,
        Filter,
        Modal,
    },
    props: {
        action: {
            type: String,
            default() {
                return null;
            }
        }
    },
    data() {
        return {
            showModal: false,
            changedPartner: null,
            updateResults: false,
            mode: 'display',
            q: '',
            qOptions: {},
            partnerFeed: [],
            contactPositions: {},
            findHandler: helpers.debounceBuffer(
                async (q, options) => {
                    this.q = q;
                    this.qOptions = options;
                    this.partnerFeed = await findPartners(q, options);
                },
                500
            ),
        };
    },
    computed: {
        globalFilter() {
            return new FilterLib();
        },
        actions() {
            return {
                client: {
                    questionnaire: {
                        title: 'Начать опрос',
                        fn: (partner, contactId) => {
                            this.actionSelectPartner(partner, contactId);
                        }
                    }
                }
            };
        },
    },
    watch: {
        mode() {
            this.updateResults = true;
        }
    },
    mounted() {
        getContactPositions(r => {
            this.contactPositions = r;
        });
    },
    methods: {
        async findResults(q, options = {}) {
            this.findHandler(q, options);
        },
        changePartner(id) {
            this.showModal = true;
            this.changedPartner = id;
        },
        closeModal() {
            this.mode = 'display';
            this.showModal = false;
            this.changedPartner = null;
            if (this.updateResults) {
                this.updateResults = false;
                this.findResults(this.q, this.qOptions);
            }
        },
        createPartner() {
            this.mode = 'add';
            this.changePartner(null);
        },
        cancelPartnerEdit() {
            if (this.mode === 'add') {
                this.closeModal();
            }
        },

        actionSelectPartner(partner, contactId = null) {
            filterHelper.selectPartnerGlobal(partner, contactId);

            if (this.action === ACTION_CART) {
                this.$emit('on-select');
                return;
            }

            this.goToQuestionnaires();
        },
        goToQuestionnaires() {
            this.$router.push('/questionnaire/categories/5507');
        },
    },
};
</script>
